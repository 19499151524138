<template>
  <div>
    <vab-query-form style="padding-top: 20px">
      <vab-query-form-left-panel :span="12">
        <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
          添加
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="12">
        <el-form :inline="true" :model="queryForm" @submit.prevent>
          <el-form-item label="渠道名称">
            <el-input v-model="queryForm.channelName" clearable placeholder="请输入渠道名称" />
          </el-form-item>
          <el-form-item>
            <el-button :icon="Search" type="primary" @click="queryData">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-right-panel>
    </vab-query-form>
    <el-table v-loading="listLoading" border :data="list">
      <el-table-column label="渠道名称" prop="channelName" show-overflow-tooltip />
      <el-table-column label="渠道备注" prop="remarks" show-overflow-tooltip />
      <el-table-column label="报名人数" prop="attendNum" show-overflow-tooltip />
      <el-table-column label="签到人数" prop="signNum" show-overflow-tooltip v-if="type == 'EXHIBITION'"/>
      <!-- <el-table-column label="渠道唯一标识" prop="channelNo" show-overflow-tooltip />
      <el-table-column label="渠道二维码" prop="channelQrcodeUrl" show-overflow-tooltip>
        <template #default="{ row }">
          <el-image fit="cover" :src="row.channelQrcodeUrl" style="width: 100px; height: 100px" />
        </template>
      </el-table-column>

      <el-table-column label="渠道链接" prop="channelUrl" show-overflow-tooltip /> -->
      <el-table-column label="操作" show-overflow-tooltip width="420">
        <template #default="{ row }">
          <el-row>
            <el-button type="primary" @click="handleEdit(row)">
              <vab-icon icon="edit-2-line" />
              编辑
            </el-button>
            <el-button type="primary" @click="handlePreview(row)">
              注册渠道码
            </el-button>
            <el-button type="primary" @click="handleHomePreview(row)">
              首页渠道码
            </el-button>
            <el-button type="primary" @click="bindUser(row)">
              绑定账号
            </el-button>
          </el-row>
          <el-row style="margin-top: 10px">
            <el-button type="success" @click="seeDetail(row, $event)">
              查看数据
            </el-button>
            <el-button type="success" @click="visitorImport(row, $event)" v-if="hostFlag == 'HOST'">
              用户导入
            </el-button>
            <el-button type="success" @click="visitorExport(row, $event)" v-if="hostFlag == 'HOST'">
              数据导出
            </el-button>
            <el-button type="success" @click="exportResult(row, $event)" v-if="hostFlag == 'HOST'">
              导入结果
            </el-button>
          </el-row>
          <!-- <el-button type="danger" @click="handleDelete(row)">
            <vab-icon icon="delete-bin-6-line" />
            删除
          </el-button> -->
        </template>
      </el-table-column>
      <template #empty>
        <!-- <el-image
                class="vab-data-empty"
                :src="require('@/assets/empty_images/data_empty.png')"
              /> -->
        <el-empty class="vab-data-empty" description="暂无数据" />
      </template>
    </el-table>
    <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    <edit ref="editRef" :event-id="queryForm.meetingCode" :meet-type='type' @fetch-data="fetchData" />

    <upload-file ref="upLoadRef" />

    <upload-result ref="resultRef" />
    <generateWxVue ref="generateWxRef"></generateWxVue>
    <export-register ref="exportRegisterRef"></export-register>

    <channelDetail ref="channelDetailRef" :meetingCode="queryForm.meetingCode"></channelDetail>
  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
  watch
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import { getChannelList, _exportUserInfo } from '@/api/channel'

import { exhibitionQueryPermissions } from '@/api/exhibitionManage'
import handleClipboard from '@/utils/clipboard'
import generateWxVue from '@/components/generateWx/generateWx.vue'
import exportRegister from './components/exportRegister.vue'
import channelDetail from './components/channelDetail.vue'
export default defineComponent({
  name: 'ChannelManagar',
  components: {
    Edit: defineAsyncComponent(() => import('./components/channelEdit')),
    uploadFile: defineAsyncComponent(() =>
      import('./components/visitorImport')
    ),
    uploadResult: defineAsyncComponent(() =>
      import('./components/uploadResult')
    ),
    generateWxVue,
    exportRegister,
    channelDetail,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        channelName: '',
        pageNum: 1,
        pageSize: 999,
        meetingCode: '',
        meetingType: '',
      },
      type: proxy.$route.query.type,
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      channelDetailRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
      hostFlag: 'HOST',
    })

    const handleEdit = (row) => {
      console.log(row)
      if (row.eventId) {
        state.editRef.showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delCoupon(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }

    const handlePreview = (row) => {
      // getQrcodeUrls(row.code, 'HOLD_EXHIBITION').then((res) => {
      //   state.qrcodeUrl = res.data[0].qrcodeUrl
      //   refExPreview.value.handleOpen()
      // })
      console.log(row)
      let qrCodeParams = {
        autoColor: false,
        b: 0,
        env: '',
        g: 0,
        jumpPath: '',
        jumpType: '',
        logo: state.queryForm.logo,
        params: `{"navTitle":"${state.queryForm.navTitle}", pageId: "${state.queryForm.pageId}", meetingCode: "${state.queryForm.meetingCode}", channelNo: "${row.channelNo}"}`,
        path: 'pages/index/index',
        r: 0,
        scenesType: 'REGISTER_CHANNEL_QRCODE',
        width: 1280,
      }
      state.generateWxRef.handleOpen(row, qrCodeParams)
    }
    const handleHomePreview = (row) => {
      let qrCodeParams = {
        autoColor: false,
        b: 0,
        env: '',
        g: 0,
        jumpPath: '',
        jumpType: '',
        logo: state.queryForm.logo,
        params: `{"navTitle":"${state.queryForm.navTitle}", pageId: "${state.queryForm.pageId}", meetingCode: "${state.queryForm.meetingCode}", channelNo: "${row.channelNo}", channelType: "EXHIBITIONHOME"}`,
        path: 'pages/index/index',
        r: 0,
        scenesType: 'SUPPER_SCENES_TYPE',
        width: 1280,
      }
      state.generateWxRef.handleOpen(row, qrCodeParams)
    }

    const queryData = () => {
      fetchData()
    }

    const copy = (row, event) => {
      handleClipboard(row.channelUrl, event)
    }
    const downLoad = (row) => {
      var image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      var _this = this
      image.onload = function () {
        var canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        var context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, image.width, image.height)
        var url = canvas.toDataURL('image/jpg') //得到图片的base64编码数据
        var a = document.createElement('a') // 生成一个a元素
        var event = new MouseEvent('click') // 创建一个单击事件
        a.download = '渠道二维码' // 设置图片名称
        a.href = url // 将生成的URL设置为a.href属性
        a.dispatchEvent(event) // 触发a的单击事件
      }
      image.src = row.channelQrcodeUrl
    }
    const fetchData = async () => {
      state.listLoading = true
      const { data } = await getChannelList(state.queryForm)
      state.list = data && data.data || []
      state.total = data && data.total || 0
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }
    watch(
      () => proxy.$route.query,
      async (newVal, oldVal) => {
        console.log(newVal, oldVal, '===1222222')
        state.type = proxy.$route.query.meetingType || 'EXHIBITION'
      }
    )

    onActivated(() => {
      console.log(proxy.$route)
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      state.queryForm.navTitle = proxy.$route.query.navTitle
      state.queryForm.pageId = proxy.$route.query.pageId
      state.queryForm.logo = proxy.$route.query.logo
      state.queryForm.meetingType =proxy.$route.query.type || 'EXHIBITION'
      state.type = proxy.$route.query.type || 'EXHIBITION'
      queryData()
      getPermissionCustomized({ code: state.queryForm.meetingCode })
    })

    // 观众根据渠道导入
    const visitorImport = (row) => {
      console.log(row)
      let obj = {
        appKey: row.appKey,
        channelName: row.channelName,
        channelNo: row.channelNo,
        channelQrcodeUrl: row.channelQrcodeUrl,
        channelUrl: row.channelUrl,
        delFlag: row.delFlag,
        eventId: row.eventId,
        id: row.id,
        meetingCode: row.meetingCode,
        remarks: row.remarks,
        meetingType: row.type,
      }
      state.upLoadRef.showEdit(obj)
    }

    // 查询导出结果
    const exportResult = (row) => {
      console.log(state.resultRef, row)
      state.resultRef.showEdit(row)
    }

    // 查看数据
    const seeDetail = (row) => {
      state.channelDetailRef.handleOpen(row, state.type, state.queryForm.meetingCode)
    }

    const visitorExport = (row) => {
      console.log(row)
      const params = {
        code: state.queryForm.meetingCode,
        channelNo: row.channelNo,
        relationType: 'EXHIBITION',
      }
      state.exportRegisterRef.handleOpen(
        params,
        `主办渠道`,
        `MEETING_CHANNEL_REGISTER_REPORT`
      )
      // _exportUserInfo(params).then((res) => {
      //   console.log(res)
      //   if (!res.data.success) {
      //     proxy.$baseMessage(
      //       `当前数据为空，无法导出`,
      //       'error',
      //       'vab-hey-message-error'
      //     )
      //   } else {
      //     window.open(res.data.fileUrl)
      //   }
      // })
    }

    const bindUser = (row) => {
      proxy.$router.push({
        path: '/exhibitionManage/bindChannelAccount',
        query: { channelId: row.id },
      })
    }

    const getPermissionCustomized = async (row) => {
      const { data } = await exhibitionQueryPermissions({
        meetingCode: row.code,
        meetingType: 'EXHIBITION',
      })

      state.hostFlag = data
    }

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      copy,
      downLoad,
      visitorImport,
      visitorExport,
      exportResult,
      Delete,
      Search,
      Plus,
      handlePreview,
      handleHomePreview,
      bindUser,
      seeDetail,
      getPermissionCustomized,
    }
  },
})
</script>
<style lang="scss" scoped></style>
